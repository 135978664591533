<div class="app-container">
  <aim-header></aim-header>

  <div class="content">
    <div class="container-fluid">
      <router-outlet></router-outlet>
    </div>
  </div>

  <aim-footer></aim-footer>
</div>
