import { Routes } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { AdminRoutes, PublicRoutes, adminRoleGuard } from 'ngx-aim';

export const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        title: 'Home',
        loadComponent: () => import('./public/home/home.component').then(c => c.HomeComponent)
      },
      {
        path: PublicRoutes.PRODUCTS,
        title: 'Products',
        loadComponent: () => import('./public/products/products.component').then(c => c.ProductsComponent)
      },
      {
        path: `${PublicRoutes.PRODUCTS}/:webCategoryName`,
        title: 'Products',
        loadComponent: () => import('./public/products/products.component').then(c => c.ProductsComponent)
      },
      {
        path: `${PublicRoutes.PRODUCT}/:model/:id`,
        title: 'Product',
        loadComponent: () => import('./public/products/product/product.component').then(c => c.ProductComponent)
      },
      {
        path: `${PublicRoutes.ITEM_RENTAL}/:model/:id`,
        title: 'Product',
        data: { isRental: true },
        loadComponent: () => import('./public/products/product/product.component').then(c => c.ProductComponent)
      },
      {
        path: PublicRoutes.CART,
        title: 'Cart',
        loadComponent: () => import('./public/cart/cart.component').then(c => c.CartComponent)
      },
      {
        path: `${PublicRoutes.CUSTOMER}/${PublicRoutes.ORDERS}`,
        title: 'Your Orders',
        loadComponent: () => import('./public/customer/customer-orders/customer-orders.component').then(c => c.CustomerOrdersComponent)
      },
      {
        path: `${PublicRoutes.CUSTOMER}/${PublicRoutes.OPEN_INVOICES}`,
        title: 'Open Invocies',
        loadComponent: () => import('./public/customer/open-invoices/open-invoices.component').then(c => c.OpenInvoicesComponent)
      },
      {
        path: `${PublicRoutes.CUSTOMER}/${PublicRoutes.OPEN_INVOICES}/${PublicRoutes.MAKE_PAYMENT}`,
        title: 'Make Payment',
        loadComponent: () => import('./public/customer/open-invoices/make-payment/make-payment.component').then(c => c.MakePaymentComponent)
      },
      {
        path: `${PublicRoutes.CUSTOMER}/${PublicRoutes.SETTINGS}`,
        title: 'Settings',
        loadComponent: () => import('./public/customer/settings/settings.component').then(c => c.SettingsComponent)
      },
      {
        path: `${PublicRoutes.CUSTOMER}/${PublicRoutes.MANAGE_CREDIT_CARD}`,
        title: 'Manage Credit Card',
        loadComponent: () => import('./public/customer/manage-credit-card/manage-credit-card.component').then(c => c.ManageCreditCardComponent)
      },
      {
        path: `${PublicRoutes.CUSTOMER}/${PublicRoutes.MANAGE_CREDIT_CARD}/${PublicRoutes.EDIT}/:AcctType`,
        title: 'Manage Credit Card',
        loadComponent: () => import('./public/customer/manage-credit-card/edit-card/edit-card.component').then(c => c.EditCardComponent)
      },
      {
        path: PublicRoutes.RENTALS,
        title: 'Rentals',
        loadComponent: () => import('./public/rentals/rentals.component').then(c => c.RentalsComponent)
      },
      {
        path: `${PublicRoutes.RENTALS}/:categorySlug`,
        title: 'Rentals',
        loadComponent: () => import('./public/rentals/rental-sub-category/rental-sub-category.component').then(c => c.RentalSubCategoryComponent)
      },
      {
        path: `${PublicRoutes.RENTALS}/:categorySlug/:subCategorySlug`,
        title: 'Rentals',
        loadComponent: () => import('./public/rentals/view-rental/view-rental.component').then(c => c.ViewRentalComponent)
      },
      {
        path: PublicRoutes.PAGE_NOT_FOUND,
        title: 'Page Not Found',
        loadComponent: () => import('./public/page-not-found/page-not-found.component').then(c => c.PageNotFoundComponent)
      },
      {
        path: PublicRoutes.CHECKOUT_COMPLETE,
        title: 'Checkout Complete',
        loadComponent: () => import('./public/checkout/checkout-complete/checkout-complete.component').then(c => c.CheckoutCompleteComponent)
      },
      {
        path: PublicRoutes.PRIVACY_POLICY,
        title: 'Privacy Policy',
        loadComponent: () => import('./public/policies/privacy-policy/privacy-policy.component').then(c => c.PrivacyPolicyComponent)
      },
      {
        path: PublicRoutes.RETURN_POLICY,
        title: 'Returns Policy',
        loadComponent: () => import('./public/policies/return-policy/return-policy.component').then(c => c.ReturnPolicyComponent)
      },
      {
        path: PublicRoutes.SHIPPING_POLICY,
        title: 'Shipping Policy',
        loadComponent: () => import('./public/policies/shipping-policy/shipping-policy.component').then(c => c.ShippingPolicyComponent)
      },
      {
        path: PublicRoutes.CONTRACT_COMPLETE,
        title: 'Checkout Complete',
        loadComponent: () => import('./public/contracts/contract-complete/contract-complete.component').then(c => c.ContractCompleteComponent)
      },
      {
        path: PublicRoutes.CONTRACTS,
        title: 'Long Term Rental',
        loadComponent: () => import('./public/contracts/contracts.component').then(c => c.ContractsComponent),
        children: [
          {
            path: PublicRoutes.SELECT_SCHOOL,
            title: 'Select a School',
            loadComponent: () => import('./public/contracts/select-school/select-school.component').then(c => c.SelectSchoolComponent)
          },
          {
            path: PublicRoutes.SELECT_INSTRUMENT,
            title: 'Select an Instrument',
            loadComponent: () => import('./public/contracts/select-instrument/select-instrument.component').then(c => c.SelectInstrumentComponent)
          },
          {
            path: PublicRoutes.OPTIONS_ACCESSORIES,
            title: 'Select Options & Accessories',
            loadComponent: () => import('./public/contracts/select-options-accessories/select-options-accessories.component').then(c => c.SelectOptionsAccessoriesComponent)
          },
          {
            path: PublicRoutes.PAYMENT,
            title: 'Payment',
            loadComponent: () => import('./public/contracts/payment/payment.component').then(c => c.PaymentComponent)
          },
          {
            path: '',
            redirectTo: `${PublicRoutes.SELECT_SCHOOL}`,
            pathMatch: 'prefix',
          },
        ]
      },
      {
        path: PublicRoutes.CONTACT_US,
        title: 'Contact Us',
        loadComponent: () => import('./public/contact-us/contact-us.component').then(c => c.ContactUsComponent)
      },
      {
        path: PublicRoutes.ABOUT_US,
        title: 'About Us',
        loadComponent: () => import('./public/about-us/about-us.component').then(c => c.AboutUsComponent)
      },
    ]
  },
  {
    path: PublicRoutes.SIGN_IN,
    title: 'Sign In',
    loadComponent: () => import('./public/sign-in/sign-in.component').then(c => c.SignInComponent)
  },
  {
    path: PublicRoutes.CREATE_ACCOUNT,
    title: 'Create Account',
    loadComponent: () => import('./public/create-account/create-account.component').then(c => c.CreateAccountComponent)
  },
  {
    path: PublicRoutes.CREATE_ACCOUNT_SUCCESSFUL,
    title: 'Create Account Complete',
    loadComponent: () => import('./public/create-account/create-account-successful/create-account-successful.component').then(c => c.CreateAccountSuccessfulComponent)
  },
  {
    path: `${PublicRoutes.ACTIONS}/${PublicRoutes.VERIFY_EMAIL}`,
    title: 'Verify Email',
    loadComponent: () => import('./public/actions/verify-email/verify-email.component').then(c => c.VerifyEmailComponent)
  },
  {
    path: `${PublicRoutes.ACTIONS}/${PublicRoutes.FORGOT_PASSWORD}`,
    title: 'Forgot Password',
    loadComponent: () => import('./public/actions/forgot-password/forgot-password.component').then(c => c.ForgotPasswordComponent)
  },
  {
    path: `${PublicRoutes.ACTIONS}/${PublicRoutes.RESET_PASSWORD}`,
    title: 'Reset Password',
    loadComponent: () => import('./public/actions/reset-password/reset-password.component').then(c => c.ResetPasswordComponent)
  },
  {
    path: PublicRoutes.CHECKOUT,
    title: 'Checkout',
    loadComponent: () => import('./public/checkout/checkout.component').then(c => c.CheckoutComponent),
  },
  {
    path: PublicRoutes.RENTAL_CHECKOUT,
    title: 'Rental Checkout',
    loadComponent: () => import('./public/rentals/rental-checkout/rental-checkout.component').then(c => c.RentalCheckoutComponent),
  },
  {
    path: AdminRoutes.ROOT,
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    canActivate: [adminRoleGuard]
  },
  {
    path: '**',
    redirectTo: PublicRoutes.PAGE_NOT_FOUND,
  },
];
